import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CategoryDateRangeFilterBar } from "../../components/filter/bar/category-date-range-filter-bar.component";
import { IFullCategoryFilterInfo } from "../../components/filter/hooks/use-selected-categories.hook";
import { useURLSelectedCategories } from "../../components/filter/hooks/use-url-selected-categories.hook";
import { LoadingCard } from "../../components/loading/loading-card.component";
import { formatDateTimeToShort } from "../../utils/date.util";
import { EventOverview } from "./event-overview.component";
import { useEventsWithFetchMore } from "./hooks/use-events-with-fetch-more.hook";
import { OVERVIEW_SPACING_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";

interface IEventsPageProps {}

export const EventsPage: React.FC<IEventsPageProps> = () => {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const { breakpoint } = useContext(LayoutContext);
  const { selectedCategories: appliedCategories, setSelectedCategories: setAppliedCategories } =
    useURLSelectedCategories();

  const { isLoadingMore, error, isInitialQueryLoading, searchResults } = useEventsWithFetchMore({
    from,
    to,
    appliedCategories,
  });

  useEffect(() => {
    setFrom(formatDateTimeToShort(DateTime.now().startOf("day")));
  }, []);

  const onApplyDateRangeFilter = useCallback((startDate: DateTime, endDate: DateTime) => {
    setFrom(formatDateTimeToShort(startDate));
    setTo(formatDateTimeToShort(endDate));
  }, []);

  const onApplyCategoryFilter = useCallback(
    (categories: IFullCategoryFilterInfo[]) => {
      setAppliedCategories(categories.map((category) => category.category));
    },
    [setAppliedCategories],
  );

  const onResetDateRangeFilter = useCallback(() => {
    setFrom(null);
    setTo(null);
  }, []);

  const onResetCategoryFilter = useCallback(() => {
    setAppliedCategories([]);
  }, [setAppliedCategories]);

  return (
    <Grid container id="events-overview-page" direction="row" sx={{ display: "block" }}>
      <CategoryDateRangeFilterBar
        applyDateRangeFilter={onApplyDateRangeFilter}
        resetDateRangeFilter={onResetDateRangeFilter}
        applyCategoryFilter={onApplyCategoryFilter}
        resetCategoryFilter={onResetCategoryFilter}
        appliedCategories={appliedCategories.map((category) => {
          return { category, isLocked: false };
        })}
        appliedFromDate={from ? DateTime.fromFormat(from, "yyyy-MM-dd") : null}
        appliedToDate={to ? DateTime.fromFormat(to, "yyyy-MM-dd") : null}
      />
      <EventOverview loading={isInitialQueryLoading} error={error} results={searchResults} />
      {(isInitialQueryLoading || isLoadingMore) && (
        <Grid
          item
          xs={12}
          container
          direction="row"
          sx={{ borderRadius: 4, p: 0, pt: 6, flex: 1, display: "flex" }}
          spacing={OVERVIEW_SPACING_FACTOR[breakpoint]}
        >
          <Grid item xs>
            <LoadingCard height={190} />
          </Grid>
          <Grid item xs>
            <LoadingCard height={190} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
