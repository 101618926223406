import { DateTime } from "luxon";
import { formatShortTime } from "./date.util";

export interface ICalEvent {
  summary: string;
  start: string;
  end: string;
  description: string;
  location: string;
  url: string;
}

export const generateICalFromEvent = (event: ICalEvent): Blob => {
  const url = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "CALSCALE:GREGORIAN",
    "METHOD:PUBLISH",
    "BEGIN:VEVENT",
    "SUMMARY:" + event.summary,
    "DTSTART;TZID=Europe/Vienna:" + event.start,
    "DTEND;TZID=Europe/Vienna:" + event.end,
    "DESCRIPTION:" + event.description,
    "LOCATION:" + event.location,
    "URL:" + event.url,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");
  const blob = new Blob([url], { type: "text/calendar" });
  return blob;
};

export const formatICalEventDateTime = (dateString: string, locale: string) => {
  const time = formatShortTime(dateString, "de").replace(":", "").replace(".", "").slice(0, 4);
  return `${DateTime.fromISO(dateString).toFormat("yyyyMMdd")}T${time}00`;
};
