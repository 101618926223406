import { Grid, Typography } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InfocenterResearchEntry } from "../../api/muu-api/graphql/generated";
import { CustomLink } from "../../components/link/custom-link.component";
import { formatMillisToLocaleDate } from "../../utils/date.util";
import { InfocenterDetailContext } from "./context/infocenter-detail.context";
import { EditorContent } from "../../components/editor/editor-content.component";

interface IInfocenterDetailResearchFieldsProps {}

export const InfocenterDetailResearchFields: FC<IInfocenterDetailResearchFieldsProps> = (props) => {
  const { infocenterEntry } = useContext(InfocenterDetailContext);
  const { t, i18n } = useTranslation();

  const infocenterResearchEntry = useMemo(() => infocenterEntry as InfocenterResearchEntry, [infocenterEntry]);

  return (
    <Grid container spacing={4}>
      {infocenterResearchEntry.link && (
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: "primaryDark.main" }}>
              {t("infocenter.research_fields.link")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomLink
              sx={{ color: "grey.400" }}
              underline="hover"
              href={infocenterResearchEntry.link}
              target="_blank"
            >
              <Typography>{infocenterResearchEntry.link}</Typography>
            </CustomLink>
          </Grid>
        </Grid>
      )}
      {infocenterResearchEntry.mode && (
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: "primaryDark.main" }}>
              {t("infocenter.research_fields.mode")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EditorContent editorContentHtmlString={infocenterResearchEntry.mode} />
          </Grid>
        </Grid>
      )}
      {infocenterResearchEntry.deadlines.length > 0 && (
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: "primaryDark.main" }}>
              {t("infocenter.research_fields.deadline")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {formatMillisToLocaleDate(infocenterResearchEntry.deadlines[0].date, i18n.language)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {infocenterResearchEntry.funders.length > 0 && (
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: "primaryDark.main" }}>
              {t("infocenter.research_fields.funders")}
            </Typography>
          </Grid>
          {infocenterResearchEntry.funders.map((funder) => (
            <Grid item xs={12} key={`funder-${funder.id}`}>
              <Typography>
                {funder.name}
                {funder.url && (
                  <>
                    {" ("}
                    <CustomLink sx={{ color: "grey.400" }} underline="hover" href={funder.url} target="_blank">
                      {funder.url}
                    </CustomLink>
                    {")"}
                  </>
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {infocenterResearchEntry.endowments.length > 0 && (
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: "primaryDark.main" }}>
              {t("infocenter.research_fields.endowment")}
            </Typography>
          </Grid>
          {infocenterResearchEntry.endowments.map((endowment) => (
            <Grid item xs={12} key={`endowment-${endowment.id}`}>
              <Typography>
                {endowment.information}{" "}
                {endowment.amount &&
                  endowment.currency &&
                  `${Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(Number(endowment.amount))} ${
                    endowment.currency
                  }`}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
