import { Box, Divider, Stack, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { FavoriteType, useCategoriesQuery, WidgetType } from "../../../api/muu-api/graphql/generated";
import { getCategoriesForSearchQueries } from "../../../app/helpers/category.helper";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { MonthSwitchFilter } from "../../filter/month-switch.filter";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { EventsPostRow } from "./events-post-row.component";
import { useEventsForWidgetHook } from "./hook/use-events-for-widget.hook";
import { WidgetNoEntry } from "../widget-no-entry.component";
import { DefaultWidgetSkeleton } from "../widget-default-skeleton.component";
import { LayoutContext } from "../../layout/layout.context";
import { WidgetFavoriteFilter } from "../../../pages/dashboard/widget-section/menu-components/widget-favorite-filter.component";
import { UserContext } from "../../../app/context/user.context";
import { useFavorites } from "../../../hooks/use-favorites.hook";
import { eventSourceToFavoriteSource } from "./helper/event-mapping.helper";
import { WidgetContentShowAll } from "../widget-content-show-all.component";

const MAX_EVENTS_POST_COUNT = 20;

interface IEventsWidgetContentProps {
  widget: IExtendedWidget;
}

export const EventsWidgetContent: React.FC<IEventsWidgetContentProps> = (props) => {
  const { widget } = props;
  const theme = useTheme();
  const [date, setDate] = useState<DateTime>(DateTime.now());
  const { language } = useContext(UserContext);
  const { data: categoriesResult } = useCategoriesQuery({
    variables: {
      language,
    },
  });
  const navigate = useNavigate();
  const { breakpointInfo } = useContext(LayoutContext);
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const categories = useMemo(() => categoriesResult?.categories || [], [categoriesResult]);
  const widgetCategories: string[] = useMemo(
    () => getCategoriesForSearchQueries(widget.categoryFilter, categories ?? []),
    [categories, widget],
  );

  const { events, loading } = useEventsForWidgetHook({
    limit: MAX_EVENTS_POST_COUNT,
    categoryNames: widgetCategories,
    date,
  });

  const { favoritesDict } = useFavorites({ type: FavoriteType.EVENT }, !events);

  const onShowAllClick = useCallback(() => {
    navigate({
      pathname: getAbsoluteRoutingPath(pageOfInterestDictionary.EVENTS.path),
    });
  }, [navigate, pageOfInterestDictionary]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {breakpointInfo.isMobile && (
        <>
          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <WidgetFavoriteFilter isSmall={false} widget={widget} color={theme.palette.text.primary} />
          </Box>
          <Divider />
        </>
      )}
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          overscrollBehavior: "contain",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction="column" display="flex" flex={1} flexDirection="column">
          <MonthSwitchFilter
            date={date}
            setDate={(date) => {
              if (date.month === DateTime.now().month) {
                setDate(date.set({ day: DateTime.now().day }));
              } else {
                setDate(date.set({ day: 1 }));
              }
            }}
          />
          <Divider />
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {loading && <DefaultWidgetSkeleton rows={5} />}
            {!loading && events.length === 0 && <WidgetNoEntry widgetType={WidgetType.EVENTS} />}
            {!loading &&
              events.map((event) => (
                <Box key={`event-widget-${event.id}`}>
                  <EventsPostRow
                    event={event}
                    favorite={
                      favoritesDict?.[FavoriteType.EVENT]?.[eventSourceToFavoriteSource(event.eventType)]?.[event.id]
                    }
                  />
                </Box>
              ))}
            {!loading && events.length >= 0 && <WidgetContentShowAll onShowAllClick={onShowAllClick} />}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
